import { graphql } from "gatsby"

import AccessoryPage from "../views/AccessoryPage"

export const query = graphql`
  query AccessoryPage($internalTitle: String!, $images: [String]!) {
    contentfulAccessoryModel(internalTitle: { eq: $internalTitle }) {
      contentful_id
      internalTitle
      title
      type
      tags
      seoTitle
      seoDescription {
        seoDescription
      }
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
      topLevelImages: images {
        internal {
          content
        }
      }
      childContentfulAccessoryModelLayoutJsonNode {
        internal {
          content
        }
      }
      variants {
        contentful_id
        title
        shopifyId
        internalTitle
        imageLayout {
          internal {
            content
          }
        }
        productListingImage {
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        color {
          hexCode
          name
        }
        accessoryUpsell {
          contentful_id
          title
          accessory_model {
            title
          }
          shopifyId
          internalTitle
          imageLayout {
            internal {
              content
            }
          }
          productListingImage {
            fluid(maxWidth: 1000, quality: 85) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          color {
            hexCode
            name
          }
        }
      }
    }
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1000, quality: 85) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default AccessoryPage
